import React, { useState } from "react";

// Libraries
import { connect } from "react-redux";

// Core
import { signInAnonymously } from "../../core/session";

// Components
import Container from "../components/container";
import Button from "../components/buttons";
import Image from "../components/images/image";
import { IconRight, IconSpin } from "../components/icons";

export default connect(null, { signInAnonymously })(({ signInAnonymously }) => {
	const [loading, toggleLoading] = useState(false);

	const submitSignIn = async () => {
		toggleLoading(true);

		await signInAnonymously();

		toggleLoading(false);
	};

	return (
		<Container>
			<div className="flex row flex-align flex-space mt-m">
				<div className="col-5">
					<Image
						width="100%"
						src={`${process.env.PUBLIC_URL}/logos/ApeldoornLogo.png`}
					/>
				</div>
				<div className="col-5">
					<Image
						width="100%"
						src={`${process.env.PUBLIC_URL}/logos/EurusLogo.png`}
					/>
				</div>
				<div className="col-2">
					<Image
						width="100%"
						src={`${process.env.PUBLIC_URL}/logos/Trimotion_logo.svg`}
					/>
				</div>
			</div>
			<h1 className="mt-m mb-m fs-l">De Gaarden</h1>
			<p className="relative mt-m">
				In beeld verschijnen stellingen onderverdeeld in 5 thema’s:
				Sociaal, Mobiliteit, Veiligheid, Duurzaamheid en Openbare
				Ruimte. Door te schuiven met het balkje onder de stelling kunt u
				aangeven in hoeverre u het ‘eens’ of ‘oneens’ bent met de
				stelling. Vervolgens klikt u op ‘volgende’ en verschijnt de
				volgende stelling in beeld. Succes met het invullen!
			</p>
			<Button
				onClick={submitSignIn}
				disabled={loading}
				className="full mt-m mb-m"
				text={loading ? "Loading..." : "Starten"}
			>
				{loading ? (
					<IconSpin className="ml icon icon-sm spin" />
				) : (
					<IconRight className="ml icon icon-sm" />
				)}
			</Button>
		</Container>
	);
});
